export default [
  {
    title: 'Dashboard',
    route: 'admin-dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Сигналы',
    route: 'admin-signals',
    icon: 'GridIcon',
  },
  {
    title: 'Сделки',
    route: 'admin-orders',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Пользователи',
    route: 'admin-users',
    icon: 'UsersIcon',
  },
  {
    title: 'Трейдеры',
    route: 'admin-traders',
    icon: 'UsersIcon',
  },
  {
    title: 'Binance logs',
    route: 'admin-binance-logs',
    icon: 'FileTextIcon',
  },
  {
    title: 'Настройки',
    route: 'admin-setting',
    icon: 'SettingsIcon',
  },
]
