<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userName }}
        </p>
        <span class="user-status">{{ role }}</span>
      </div>
      <b-avatar
        size="40"
        :src="avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
      </b-avatar>
    </template>

    <b-dropdown-item
        v-for="item in dropDownMenu"
        :key="item.route"
        :to="{ name: item.route }"
        link-class="d-flex align-items-center"
    >
      <feather-icon
          size="16"
          :icon="item.icon"
          class="mr-50"
      />
      <span>{{ item.name }}</span>
    </b-dropdown-item>

    <div
      class="logout-item"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Выход</span>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  data() {
    return {
      avatar: '',
      avatarText,
      adminMenu: [
        {
          route: 'admin-setting',
          icon: 'SettingsIcon',
          name: 'Настройки',
        },
        // {
        //   route: 'admin-setting',
        //   icon: 'CreditCardIcon',
        //   name: 'Цены',
        // },
        // {
        //   route: 'admin-setting',
        //   icon: 'HelpCircleIcon',
        //   name: 'FAQ',
        // },
      ],
      clientMenu: [
        {
          route: 'client-setting',
          icon: 'SettingsIcon',
          name: 'Настройки',
        },
        {
          route: 'client-pricing',
          icon: 'CreditCardIcon',
          name: 'Цены',
        },
      ],
      traderMenu: [
        {
          route: 'trader-setting',
          icon: 'SettingsIcon',
          name: 'Настройки',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      userName: state => state.auth.userName,
      role: state => state.auth.role,
    }),
    dropDownMenu() {
      if (this.role === 'admin') {
        return this.adminMenu
      } else if (this.role === 'client') {
        return this.clientMenu
      } else {
        return this.traderMenu
      }
    },
  },
  methods: {
    ...mapActions({
      destroyToken: 'auth/destroyToken',
    }),
    logout() {
      // Reset ability
      this.$ability.update(initialAbility)

      this.destroyToken()
        .then(() => this.$router.push({ name: 'auth-login' }))
    },
  },
}
</script>

<style scoped>
  .logout-item {
    color: #b4b7bd;
    cursor: pointer;
    padding: 0.65rem 1.28rem;
  }

  .logout-item:hover {
    color: #7367f0;
    background: rgba(115, 103, 240, 0.12);
  }
</style>
