export default [
  {
    title: 'Dashboard',
    route: 'trader-dashboard',
    icon: 'HomeIcon',
  },
  // {
  //   title: 'Сигналы',
  //   route: 'trader-signals',
  //   icon: 'GridIcon',
  // },
  // {
  //   title: 'Сделки',
  //   route: 'trader-orders',
  //   icon: 'BriefcaseIcon',
  // },
  {
    title: 'Binance logs',
    route: 'trader-binance-logs',
    icon: 'FileTextIcon',
  },
  {
    title: 'Настройки',
    route: 'trader-setting',
    icon: 'SettingsIcon',
  },
]
